import { inject, Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { BaseApi } from '@ministrary/shared/apis/base.api';
import { ChurchService } from '@ministrary/shared/services/church/church.service';

import { iGroupGeneralSettings } from '../interfaces/group-general-settings.interface';

@Injectable({
  providedIn: 'root'
})
export class GroupGeneralSettingsApi extends BaseApi<iGroupGeneralSettings> {
  private churchService = inject(ChurchService);

  constructor() {
    super('group_general_settings', environment.apiUrl);
  }

  async getByChurchId() {
    const church_id = this.churchService.churchId;

    const { data } = await this.select('*').match({ church_id, active: true }).returns<iGroupGeneralSettings>().maybeSingle();
    return data as unknown as iGroupGeneralSettings;
  }
}
