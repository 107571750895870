import { inject, Injectable, signal } from '@angular/core';
import { GroupGeneralSettingsApi } from '@domain/groups/apis/group-general-settings.api';
import { iGroupGeneralSettings } from '@domain/groups/interfaces/group-general-settings.interface';
import { eProduct } from '@ministrary/shared/enums/product.enum';
import { ChurchService } from '@ministrary/shared/services/church/church.service';

@Injectable({
  providedIn: 'root'
})
export class GroupGeneralSettingsService {
  private churchService = inject(ChurchService);
  private groupGeneralSettingsApi = inject(GroupGeneralSettingsApi);

  generalSettings = signal<iGroupGeneralSettings | undefined>(undefined);

  async load() {
    if (!this.churchService.hasProduct([eProduct.GROUPS])) return;

    const generalSettings = await this.groupGeneralSettingsApi.getByChurchId();
    this.generalSettings.set(generalSettings);

    if (generalSettings) {
      this.generalSettings.set(generalSettings);
    } else {
      const { data } = await this.groupGeneralSettingsApi
        .insert({
          church_id: this.churchService.churchId!,
          enable_search: false
        })
        .select('*')
        .single();

      if (data) {
        this.generalSettings.set(data);
      }
    }
  }

  async update(data: Partial<iGroupGeneralSettings>) {
    const { data: updatedData } = await this.groupGeneralSettingsApi.update(data).match({ church_id: this.churchService.churchId }).select('*').single();

    if (updatedData) {
      this.generalSettings.set(updatedData);
    }
  }
}
